import React from "react";
import { NavLink } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoWalletOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import ServiceImage from "../components/ServiceImage";

const images = [
  {
    src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
    width: 320,
    height: 174,
    isSelected: true,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
    width: 320,
    height: 212,
    tags: [
      { value: "Ocean", title: "Ocean" },
      { value: "People", title: "People" },
    ],
    alt: "Boats (Jeshu John - designerspics.com)",
  },

  {
    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
    width: 320,
    height: 212,
  },
];

function ResidentialPage() {
  return (
    <div>
      <div className="container py-10 px-5 xl:px-20 mx-auto flex-col-reverse md:flex-row flex md:space-x-5">
        <div className=" w-full md:w-1/4 xl:w-1/5 mt-10 md:mt-0">
          <ul className="list-none flex flex-col space-y-2">
            <li>
              <NavLink
                to="/services/residential"
                end
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "flex py-4 px-4 justify-between bg-white shadow-md items-center hover:text-white hover:bg-primary"
                }
              >
                Residential <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services/commercial"
                end
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between bg-white shadow-md items-center hover:text-white hover:bg-primary"
                }
              >
                Commercial <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services/outdoor"
                end
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between bg-white items-center shadow-md hover:text-white hover:bg-primary"
                }
              >
                Outdoor Space <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
          </ul>
          <div className="border bg-white shadow-md rounded border-[#E2E6E7] mt-10 h-[400px] py-10 px-5 ">
            <span className="block">
              <IoWalletOutline className="text-primary border border-[#E2E6E7] rounded-full p-4 text-8xl text-center mx-auto" />
            </span>
            <h4 className="text-2xl text-secondary text-corm font-bold text-center mt-5">
              Don't Hesitate
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5 mx-auto"></div>
            <p className="font-open text-secondary mt-5 text-base">
              Trusted by your neighbors and communities near you
            </p>
            <div className="text-center mt-10">
              <Link
                to="/contact"
                className=" bg-primary hover:bg-primaryDark transition-all ease-in-out duration-300 py-2 px-3 rounded-md shadow-md text-white"
              >
                Request A Quote
              </Link>
            </div>
          </div>
        </div>

        {/* Second Column */}
        <div className=" w-full md:w-3/4 xl:w-4/5">
          <div className="w-full flex mb-10">
            <img
              className="block w-1/2 pr-1 md:pr-5"
              src="https://images.unsplash.com/photo-1560185127-2d06c6d08d3d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <img
              className="w-block w-1/2 pl-1 md:pl-5"
              src="https://images.unsplash.com/photo-1584622650111-993a426fbf0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-2xl text-darkText text-corm font-bold">
              Residential Services
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5"></div>
            <p className="font-open text-textGray mt-10 text-base">
              At E Daley Contracting, we have extensive experience in
              residential construction projects. We have completed a wide
              variety of projects ranging from remodels, additions, and full
              renovations. We understand the importance of quality craftsmanship
              and attention to detail, and that’s why we pride ourselves on
              delivering the highest quality results. Our team is composed of
              experienced professionals who have years of experience in the
              industry, and we understand the importance of getting the job done
              right the first time. We believe in using the latest technologies
              and materials to ensure that our projects are completed on time
              and within budget. Our team works closely with our clients to
              ensure that we understand their needs and that the project is
              completed to their satisfaction. At E Daley Contracting, we
              understand the importance of meeting deadlines and delivering the
              highest quality results. We make sure to stay up to date with the
              latest trends and technologies to ensure that our projects are
              completed to the highest standards. Contact us today to learn more
              about our residential construction projects!
            </p>
            <p className="font-open text-textGray mt-10 text-base">
              E Daley Contracting has proven results for setting exceptional
              standards in cost control, planning, scheduling and project
              safety. We have experience that gives us a competitive advantage
              over others in our field.
            </p>
            <h4 className="text-2xl text-darkText text-corm font-bold mt-10">
              We Provide A Wide Array Of Residential Services, Including:
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5"></div>
            <ul className="mt-10">
              <li className="text-textGray font-open">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Designing Blueprints and/or 3D Modelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Full Kitchen Remodelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Full Bathroom Remodelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Drywall Install and Finishing
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Painting and Mudding
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Flooring and Tiling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Duct Cleaning
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Full Electrical Services
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Full Plumbing Services
                </div>
              </li>
            </ul>
          </div>
          <ServiceImage />
        </div>
      </div>
    </div>
  );
}

export default ResidentialPage;
