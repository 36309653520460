import React from "react";

function Members() {
  return (
    <div className="container border-[#757980] border-t-[1px] border-opacity-10 px-5 lg:px-20 py-10 flex flex-col md:flex-row md:justify-between mx-auto space-y-5 md:space-y-0">
      <div className="w-full md:w-1/2  flex flex-col">
        <h1 className="text-xl lg:text-2xl text-secondary font-bold tracking-widest mb-3 text-center">
          Ewan Daley
        </h1>
        <h4 className="text-sm text-secondary px-5 md:px-0 tracking-widest pb-3 text-center">
          CO-FOUNDER
        </h4>
        <div className="w-[60px] h-[2px] bg-primary mx-auto mb-5 "></div>
        <p className="text-[#757980] text-center">
          Ewan Daley Jr. President, with over a decade of experience in the
          architecture and construction industry.
        </p>
      </div>

      <div className="w-full px-5 md:px-0 md:w-1/2 flex flex-col">
        <h1 className="text-xl lg:text-2xl text-secondary font-bold tracking-widest mb-3 text-center">
          Justin Antoine
        </h1>
        <h4 className="text-sm text-secondary tracking-widest pb-3 text-center">
          CO-FOUNDER
        </h4>
        <div className="w-[60px] h-[2px] bg-primary mx-auto mb-5 "></div>
        <p className="text-[#757980] text-center">
          Certified Legal (HCRA - Home Construction Regulatory Course)
          professional.
        </p>
      </div>
    </div>
  );
}

export default Members;
