import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaPencilRuler,
  FaToilet,
  FaCouch,
  FaTools,
  FaBrush,
  FaDoorOpen,
  FaPenAlt,
  FaArrowRight,
} from "react-icons/fa";
import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";

const sliderData = [
  {
    title: "Permit Drawings",
    description:
      "We provide architectural permit drawings for residential and commercial properties. Our drawings are tailored to each of our client's individual needs and requirements. We create detailed and accurate plans that meet local building codes, while also providing a design that meets our client's needs. We also offer a fast turnaround time so projects can move quickly and without delay.   ",
    image:
      "https://images.unsplash.com/photo-1503387762-592deb58ef4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1331&q=80",
    icon: <FaPencilRuler className="text-3xl text-white" />,
  },
  {
    title: "Kitchen & Bathroom Remodel",
    description:
      "Are you looking to upgrade your kitchen and bathroom to give it a fresh, modern look? Look no further! Our experienced design team provides superior solutions for your kitchen and bathroom remodeling needs. We offer a wide range of services, from flooring to cabinetry and countertops, at competitive prices. With our professional installation and attention to detail, you can trust that your renovation will be completed with the highest quality standards.  ",
    image:
      "https://images.unsplash.com/photo-1613688684111-5c197974be20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
    icon: <FaToilet className="text-3xl text-white" />,
  },
  {
    title: "Basement Remodel",
    description:
      "Are you planning to renovate your basement, but don't have the financing or permits? We have the solution! Our team specializes in basement renovation financing and permitting, ensuring you have all the resources you need to get your project off the ground. With our help, you can make your basement dreams come true without having to worry about the financial or legal aspects. Let us take care of it, so you can start renovating today.  ",
    image:
      "https://images.unsplash.com/photo-1646592491963-07ff7e7c31f7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    icon: <FaCouch className="text-3xl text-white" />,
  },
  {
    title: "Flooring",
    description:
      "If you're looking for quality flooring work that you can trust, look no further! Our team of experienced professionals offer a wide range of services from installation to repair, as well as custom work. We only use the best materials and provide competitive prices for all our services. We make sure that our customers are satisfied with the results, every time.  ",
    image:
      "https://images.unsplash.com/photo-1613621792067-8e28d16b735c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    icon: <FaTools className="text-3xl text-white" />,
  },
  {
    title: "Landscape/Hardscape",
    description:
      "We are a full-service landscape/hardscape & construction company that specializes in creating beautiful, functional outdoor spaces. We will work with you to develop a plan that makes the most of your outdoor space and fits your budget. Our experienced team can take on any project, from simple landscape updates to major hardscape renovations. We also offer a variety of construction services, from fence and deck installation to custom outdoor living structures. Our commitment to quality and customer satisfaction ensures that we deliver the highest level of service every time",
    image:
      "https://images.unsplash.com/photo-1613544723371-23b514a78c85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    icon: <FaTools className="text-3xl text-white" />,
  },
  {
    title: "Drywall Finishing & Painting",
    description:
      "If you need to spruce up your home or business, E Daley Contracting is your go-to. We provide quality services with a focus on detail. We'll take care of everything from patching up damaged walls to giving your space a fresh new look - all with a quick turnaround and an eye for perfection. Whether you're looking for a subtle makeover or a complete overhaul, E Daley Contracting is the perfect choice. ",
    image:
      "https://plus.unsplash.com/premium_photo-1682597001042-c130c0627a7e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    icon: <FaBrush className="text-3xl text-white" />,
  },
  {
    title: "Doors & Windows",
    description:
      "If you're looking for experienced professionals to take care of your door and window installation, removal, or repair needs, look no further! Our team of experienced professionals has been providing top-notch service for more than 20 years. We use the highest quality materials and industry-leading practices to ensure that every job is done to the highest standards. Our services are fast, reliable, and cost-effective, so you can trust that you're getting the best value for your money. Contact us today for more information!  ",
    image:
      "https://images.unsplash.com/photo-1628012209120-d9db7abf7eab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=736&q=80",
    icon: <FaDoorOpen className="text-3xl text-white" />,
  },
  {
    title: "3D Images (Rendering)",
    description:
      "We offer high-quality architectural renders for residential and commercial properties. Our renders help our clients visualize their projects before they start building. We use the latest technology to create realistic 3D images that give an accurate representation of the space and help our clients understand the design intent. Our services are fast, reliable, and cost-effective, so let us help you bring your ideas to life!  ",
    image:
      "https://images.unsplash.com/photo-1542621334-a254cf47733d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    icon: <FaPenAlt className="text-3xl text-white" />,
  },
];

const variants = {
  enter: { opacity: 0, y: -100 },
  center: { opacity: 0.95, y: 0 },
  exit: {
    opacity: 0,
    y: -100,
  },
};

function Slider() {
  const [clicked, setClicked] = useState(false);
  const [itemId, setItemId] = useState(null);

  const clickHandler = (index) => {
    setClicked(!clicked);
    index === itemId ? setItemId(null) : setItemId(index);
  };
  return (
    <div className="py-10 md:py-14 border-b-[1px] border-[#757980] border-opacity-10 bg-bodyColor">
      <div className="flex items-center justify-center space-x-2">
        <div className="flex flex-col space-y-1">
          <div className="h-[2px] w-[15px] bg-primary" />
          <div className="h-[2px] w-[20px] bg-primary" />
        </div>
        <h2 className="text-primary text-lg font-mainFont font-semibold">
          Our Specialties
        </h2>
      </div>
      <h1 className="text-center my-2 text-3xl text-secondary font-mainFont font-semibold tracking-wide">
        High Quality Service
      </h1>
      <div className="px-5 lg:px-20 container mx-auto">
        <Swiper
          modules={[Autoplay, Pagination]}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4500,
            disableOnInteraction: true,
          }}
          breakpoints={{
            // when window width is >= 576px
            576: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={20}
          slidesPerView={1}
        >
          {sliderData.map((slide, index) => (
            <SwiperSlide className="py-6" key={index}>
              <motion.div
                className="relative h-[550px] bg-white rounded-lg shadow-lg mb-4"
                onClick={() => clickHandler(index)}
              >
                <img
                  src={slide.image}
                  className="w-full h-1/2 object-cover rounded-t-lg border-b-2 border-secondary"
                  alt=""
                />
                <div
                  className="w-[65px] h-[65px] lg:w-[75px] lg:h-[75px] absolute top-[50%] left-[10%] -translate-y-[50%]
                 bg-secondary rounded-full flex items-center justify-center"
                >
                  {slide.icon}
                </div>
                <h1 className="px-3 text-center mt-12 lg:mt-12 text-base lg:text-xl text-secondary font-mainFont font-semibold tracking-wide">
                  {slide.title}
                </h1>
                <p className="px-3 text-sm mt-2 font-mainFont text-[#757980]">
                  {slide.description.slice(0, 100) + "..."}
                </p>
                <div className="px-3 mt-6 lg:mt-8">
                  <div className=" px-3 h-[1.4px] bg-slate-700 opacity-20"></div>
                </div>
                <div className="text-center mt-6 lg:mt-8">
                  <button
                    type="button"
                    className="font-mainFont text-base lg:text-lg bg-primary text-white px-4 py-3  rounded-md hover:bg-primaryDark transition-all ease-in-out shadow duration-300"
                    onClick={() => clickHandler(index)}
                  >
                    Read More
                  </button>
                </div>
                <AnimatePresence variants={variants}>
                  {itemId === index && (
                    <motion.div
                      key={index}
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      className="absolute top-0 left-0 h-full w-full bg-secondary rounded p-5"
                    >
                      <div className="p-5 text-[.9rem] border-[1px] rounded border-primary">
                        <p className="font-mainFont text-white tracking-wide">
                          {slide.description}
                        </p>
                        <div className="text-center underline mt-5 text-primary">
                          <Link to="/services" className="font-semibold">
                            Services
                            <FaArrowRight className="ml-2 text-sm inline" />
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Slider;
