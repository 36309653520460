import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const headerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
      delayChildren: 1,
    },
  },
};

const headerChildrenVariants = {
  hidden: { opacity: 0, y: "500%" },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

function Hero() {
  return (
    <div className="w-full  h-screen md:h-[calc(100vh-85.33px)] relative font-mainFont">
      <motion.div
        variants={headerVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 2 }}
        className="absolute top-[40%] md:top-[30%] z-10 left-[10%]"
      >
        <h4 className="mb-5 md:mb-14 text-base md:text-lg tracking-widest uppercase text-white">
          Renovations
        </h4>
        <h2 className="text-4xl md:text-7xl text-white font-mainFont font-semibold">
          Building <span className="text-primary">Quality</span> <br /> Creating
          Value
        </h2>
        <div className="flex items-center space-x-2 md:space-x-5 text-white text-base md:text-[1.8rem] mt-5">
          <h2>Commercial</h2>
          <div className="w-[8px] h-[8px] md:w-[11px] md:h-[11px] bg-primary rounded-full block mt-1"></div>
          <h2>Residential</h2>
          <div className="w-[8px] h-[8px] md:w-[11px] md:h-[11px] bg-primary rounded-full block mt-1"></div>
          <h2>Outdoor</h2>
        </div>
        <motion.div variants={headerChildrenVariants} className="mt-12">
          <Link
            to="/services"
            className="bg-primary text-white p-2 px-5 text-lg  md:text-xl rounded-md font-mainFont"
          >
            Find Out More
          </Link>
        </motion.div>
      </motion.div>
      {/* <div className="bg-[#F0F1F2] w-[600px] h-full absolute top-0 left-0"></div> */}

      <img
        src="https://images.unsplash.com/photo-1596204976717-1a9ff47f74ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
        alt=""
        className="object-cover block w-full h-full"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
    </div>
  );
}

export default Hero;
