import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import emailjs from "@emailjs/browser";
import "leaflet/dist/leaflet.css";

const initialState = {
  firstName: "",
  lastName: "",
  userEmail: "",
  description: "",
};

function Kontakt() {
  const [formValues, setFormValues] = useState(initialState);
  const form = useRef();

  const { firstName, lastName, userEmail, description } = formValues;

  const formHandler = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const result = await emailjs.sendForm(
        "service_udc1sfc",
        "template_2sjxuci",
        form.current,
        "NfDwP4xjBMtekZT5U"
      );

      console.log(result);

      setFormValues({
        firstName: "",
        lastName: "",
        userEmail: "",
        description: "",
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  return (
    <div className=" w-full bg-primary">
      <div className="container h-2/3 mx-auto p-5 md:p-20 flex-col lg:flex-row flex">
        <div className="h-1/2 w-full">
          <div className="bg-secondary w-[400px] px-10 py-5 rounded-md shadow-lg">
            <h4 className="text-center text-xl tracking-widest text-secondary font-semibold mb-3">
              Contact us
            </h4>
            <h2>HAVE QUESTIONS? GET IN TOUCH!</h2>
            <form
              ref={form}
              className="w-full mt-5 lg:pr-3"
              onSubmit={submitHandler}
            >
              <div className="flex flex-wrap">
                <div className="w-1/2 pr-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    First Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-bgGray text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    value={firstName}
                    onChange={formHandler}
                  />
                </div>
                <div className="w-1/2 pl-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-bgGray text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    value={lastName}
                    onChange={formHandler}
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  email
                </label>
                <input
                  className="appearance-none block w-full bg-bgGray text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="email"
                  placeholder="Email address"
                  name="userEmail"
                  value={userEmail}
                  onChange={formHandler}
                />
              </div>
              <div className="w-full mt-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="description"
                >
                  Description
                </label>
                <textarea
                  className="appearance-none block w-full bg-bgGray text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="description"
                  placeholder="Description"
                  rows="6"
                  col="50"
                  name="description"
                  value={description}
                  onChange={formHandler}
                />
              </div>
              <div className="text-center my-5">
                <button className="w-full bg-secondary hover:bg-primaryDark transition-all ease-in-out duration-300 py-2 px-3 rounded-md shadow-lg text-white text-xl">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>Hi</div>
      </div>
    </div>
  );
}

export default Kontakt;
