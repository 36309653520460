import React, { useState } from "react";
import imageOne from "../assets/image0.jpeg";
import imageFive from "../assets/image5.jpeg";
import imageTen from "../assets/image10.jpg";
import imageEleven from "../assets/IMG_5954.JPG";
import door from "../assets/door.jpeg";

const images = [
  { id: 1111, image: imageOne },
  { id: 2222, image: imageFive },
  { id: 3333, image: imageTen },
  { id: 4444, image: imageEleven },
  { id: 5555, image: door },
];

function ServiceImage() {
  const [mainImage, setMainImage] = useState(images[0]);

  return (
    <div className="mt-10`">
      <h4 className="text-2xl text-darkText text-corm font-bold mt-10">
        Gallery of Work
      </h4>
      <div className="w-10 h-[2px] bg-primary mt-5 mb-10"></div>
      <img
        src={mainImage.image}
        className="h-[350px] md:h-[600px] object-cover w-full rounded"
        alt="door"
      />
      <div className="mt-5 w-full flex h-[90px] md:h-[200px] md:space-x-5 justify-between">
        {images.map((i, index) => (
          <img
            src={i.image}
            className={
              i.id === mainImage.id
                ? "block h-full w-[19%] object-cover cursor-default border-4 border-primary rounded shadow-md"
                : " h-full block w-[19%] object-cover cursor-pointer rounded  transition-all md:hover:scale-[1.1] duration-200 ease-in-out"
            }
            alt=""
            key={index}
            onClick={() => setMainImage(images[index])}
          />
        ))}
      </div>
    </div>
  );
}

export default ServiceImage;
