import React from "react";
import logo from "../assets/footerlogo.svg";
import { FaLink, FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineMail } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";

function Footer() {
  return (
    <div className="min-h-80 border-t-[1px] border-[#757980] border-opacity-10 bg-secondary font-mainFont">
      <div className="container py-10 px-10 md:px-20 mx-auto flex-col md:flex-row md:flex-wrap lg:flex-nowrap flex lg:space-x-10">
        <div className="w-full md:w-1/2 lg:w-1/4 border-b pb-10 lg:pb-0 border-textGray border-opacity-75 md:border-0">
          <img src={logo} className="w-[150px] mx-auto md:mx-0 mb-10" alt="" />
          <p className="text-white text-base lg:text-lg">
            Turning your dream home into reality by remodelling from the ground
            up
          </p>
          <div className="flex justify-center w-full mt-5 text-white font-mainFont space-x-5 text-l">
            <a
              href="https://twitter.com/EdaleyReno416"
              className="cursor-pointer"
            >
              <FaTwitter className="text-footerText text-lg" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100083604968403&mibextid=LQQJ4d"
              className="cursor-pointer"
            >
              <FaFacebookF className="text-footerText text-lg" />
            </a>
            <a
              href="https://instagram.com/edaley.gta?igshid=MWQ2ODkyMjM="
              className="cursor-pointer"
            >
              <FaInstagram className="text-footerText text-lg" />
            </a>
            <a
              href="https://www.linkedin.com/in/e-daley-contracting-9a7b7026a"
              className="cursor-pointer"
            >
              <FaLinkedinIn className="text-footerText text-lg" />
            </a>
          </div>
        </div>
        <div className="w-full mt-10 md:mt-0 md:w-1/2 lg:w-1/4 h-full md:pl-10 border-b pb-10 lg:pb-0 border-textGray border-opacity-75 md:border-0 mx-auto">
          <h4 className="text-xl text-white text-open mb-5">Services</h4>
          <ul>
            <li className="text-white">
              <div className="flex items-center">
                <span className="text-primary mr-5">
                  <FaCheck />
                </span>
                Interior Remodelling
              </div>
            </li>
            <li className="text-white mt-2">
              <div className="flex items-center">
                <span className="text-primary mr-5">
                  <FaCheck />
                </span>
                Exterior Remodelling
              </div>
            </li>
            <li className="text-white mt-2">
              <div className="flex items-center">
                <span className="text-primary mr-5">
                  <FaCheck />
                </span>
                Driveway/Pathway
              </div>
            </li>
            <li className="text-white mt-2">
              <div className="flex items-center">
                <span className="text-primary mr-5">
                  <FaCheck />
                </span>
                Basement/Kitchens
              </div>
            </li>
            <li className="text-white mt-2">
              <div className="flex items-center">
                <span className="text-primary mr-5">
                  <FaCheck />
                </span>
                Windows/Doors
              </div>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 mt-10 md:mt-0 h-full md:pl-10 border-b pb-10 md:pb-0 border-textGray border-opacity-75 md:border-0">
          <h4 className="text-xl text-white text-open mb-5">Categories</h4>
          <ul>
            <li className="text-white mt-2 ">
              <div className="flex items-center">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "text-primary" : "hover:text-primary"
                  }
                >
                  <span className="text-primary mr-5">•</span>
                  Home
                </NavLink>
              </div>
            </li>
            <li className="text-white mt-2 ">
              <div className="flex items-center">
                <NavLink
                  to="/services"
                  className={({ isActive }) =>
                    isActive ? "text-primary" : "hover:text-primary"
                  }
                >
                  <span className="text-primary mr-5">•</span>
                  Services
                </NavLink>
              </div>
            </li>

            <li className="text-white mt-2 ">
              <div className="flex items-center">
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? "text-primary" : "hover:text-primary"
                  }
                >
                  <span className="text-primary mr-5">•</span>
                  About us
                </NavLink>
              </div>
            </li>
            <li className="text-white mt-2">
              <div className="flex items-center">
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? "text-primary" : "hover:text-primary"
                  }
                >
                  <span className="text-primary mr-5 ">•</span>
                  Contact us
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
        <div className="w-full mt-10 md:mt-0 md:w-1/2 lg:w-1/4 h-full">
          <h4 className="text-base md:text-xl text-white text-open mb-5">
            Call Now For Services! <br />
            <a className="text-base" href="tel:416-509-0853">
              (437)-995-1757
            </a>
          </h4>
          <h4 className="flex space-x-2 items-center text-white text-open mb-5">
            <span className="text-primary mr-5 text-lg">
              <MdOutlineMail />
            </span>
            <a href="mailto:edaley.gta@gmail.com">Email us</a>
          </h4>
          <h4 className="text-base  text-white text-open mb-5">
            Serving the GTA and surrounding area
          </h4>
          {/* <h4 className="flex space-x-2 items-center text-white text-open mb-5">
            <span className="text-primary mr-5 text-lg">
              <SlLocationPin />
            </span>
            <a href="tel:437-995-1757">Get Directions</a>
          </h4> */}
        </div>
        {/* <h4 className="text-center text-xl text-gray-300 font-corm">
          Daley Contracting
        </h4>
        <div className="flex w-full justify-center mt-2 text-gray-300 space-x-5 text-l">
          <FaTwitter />
          <FaFacebookF />
          <FaInstagram />
          <FaLinkedinIn />
        </div>
        <h4 className="text-center text-gray-300 font-corm mt-2">
          2022 © DaleyContracting. All Rights Reserved
        </h4> */}
      </div>
      <div className="container px-5 md:px-20 h-32 mx-auto">
        <div className="border-t border-textGray w-full opacity-50"></div>
        <p className="text-white text-center mt-10">
          E Daley Contreacting © 2022 All Rights Reserved. Terms of Use and
          Privacy Policy
        </p>
      </div>
    </div>
  );
}

export default Footer;
