import React from "react";
import living from "../assets/livingDesign.jpg";
import kitchen from "../assets/kitchenDesign.jpg";
import { Link } from "react-router-dom";

function Divider() {
  return (
    <div className="bg-white py-10">
      <div className="container px-5 lg:px-20 mx-auto">
        <div className="flex lg:flex-row flex-col space-x-0 space-y-4 lg:space-y-0 lg:space-x-10">
          <div className="w-full lg:w-1/2 mb-0 lg:mb-10">
            <img
              src={living}
              className="h-[350px] w-full lg:h-[600px] object-cover rounded"
              alt=""
            />
          </div>
          <div className="h-[350px] lg:h-[600px] w-full lg:w-1/2 bg-secondary rounded shadow-lg p-5 lg:p-20">
            <h1 className="text-base lg:text-2xl text-center lg:text-left capitalize font-mainFont font-semibold text-white">
              We help you bring the most complex projects to life
            </h1>
            <div className="h-[1px] lg:h-[3px] w-full lg:w-[65px] bg-primary mt-4 lg:mt-8"></div>
            <p className="mt-4 lg:mt-8 text-center lg:text-left text-sm lg:text-base tracking-wide  mb-6 lg:mb-20 bg-secondary text-white font-mainFont">
              E Daley Contracting can turn ideas into reality by 3D rendering
              and virtual designs. We can assist with creating foor plans for
              interior/exterior for all properties. Also we can assist in
              virtually staging homes.
            </p>
            <Link
              to="/services"
              className="block text-center w-full font-mainFont text-white bg-primary mx-auto py-2 px-3 rounded-md shadow-md hover:bg-primaryDark transition-all ease-in-out duration-300"
            >
              Learn More
            </Link>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col-reverse space-x-0 space-y-4 lg:space-y-0 lg:space-x-10 ">
          <div className="h-[350px] lg:h-[600px] w-full lg:w-1/2 mt-4 lg:mt-0  bg-secondary rounded shadow-lg p-5 lg:p-20">
            <h1 className="text-base text-center lg:text-left lg:text-2xl capitalize font-mainFont font-bold text-white">
              Creating exclusive projects with the highest quality material
            </h1>
            <div className="h-[1px] lg:h-[3px] lg:w-[65px] bg-primary mt-4 lg:mt-8 w-full"></div>
            <p className="mt-4 text-center lg:text-left lg:mt-8 text-sm lg:text-base tracking-wide  mb-6 lg:mb-20 bg-secondary text-white font-mainFont">
              We have a wide variety of high-quality options to choose from that
              will take your project to the next level. From beautifully made
              doors, classy trims, and intricate mouldings to luxury flooring,
              stylish bath vanities, and many more premium choices.
            </p>
            <Link
              to="/services"
              className="block text-center font-mainFont text-white bg-primary py-2 px-3 rounded-md shadow-md hover:bg-primaryDark transition-all ease-in-out duration-300"
            >
              Learn More
            </Link>
          </div>
          <div className="w-full lg:w-1/2">
            <img
              src={kitchen}
              className="h-[350px] w-full lg:h-[600px] object-cover rounded"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Divider;
