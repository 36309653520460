import React from "react";
import { Link } from "react-router-dom";
import worker from "../assets/worker.jpg";

function WeDo() {
  return (
    <div className=" bg-white ">
      <div className=" flex flex-col lg:flex-row container mx-auto py-10 px-5 md:px-10 lg:px-20 space-y-10 lg:space-y-0">
        <div className=" h-full w-full">
          <div className="flex items-center justify-center space-x-2">
            <div className="flex flex-col space-y-1">
              <div className="h-[2px] w-[15px] bg-primary" />
              <div className="h-[2px] w-[20px] bg-primary" />
            </div>
            <h2 className="text-primary text-lg font-mainFont font-semibold">
              What We Do
            </h2>
          </div>
          <h1 className="text-secondary text-xl lg:text-4xl font-corm font-bold mt-2 text-center lg:text-left capitalize">
            Experienced, skilled & reliable.
          </h1>
          <p className=" text-secondary text-sm lg:text-base tracking-wide mt-6 w-full lg:w-3/4">
            Our team of experts will work with you to create a custom design
            that fits your style and budget. We use only the highest quality
            materials and our work is backed by a satisfaction guarantee.
            Whether you’re looking to update your home for your own enjoyment or
            to increase its resale value, we can help. Contact us today to
            schedule a consultation.
          </p>
          <div className="text-center mt-6">
            <Link
              to="/about"
              className="bg-primary block lg:inline text-white hover:bg-primaryDark transition-all ease-in-out duration-300 py-2 px-3 rounded-md shadow-md "
            >
              Meet the team
            </Link>
          </div>
        </div>
        {/* <div className="object-cover self-start h-full w-full"> */}
        <img
          src={worker}
          className="h-full w-full lg:w-1/2 ml-auto block object-cover"
          alt=""
        />
        {/* </div> */}
      </div>
    </div>
  );
}

export default WeDo;
