import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function ServicePage() {
  const navigate = useNavigate();

  const clickResidential = () => {
    navigate("/services/residential");
  };
  const clickCommercial = () => {
    navigate("/services/commercial");
  };
  const clickOutdoor = () => {
    navigate("/services/outdoor");
  };

  return (
    <>
      {/* Service selection */}
      <div className="bg-bodyColor w-full h-full">
        <div className="container px-5  xl:px-20 mx-auto py-10 flex flex-col md:flex-row md:space-x-5 space-y-10 md:space-y-0">
          <div onClick={clickResidential}>
            <div className="rounded bg-white shadow-md hover:shadow-xl hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer">
              <div>
                <img
                  className="rounded-t object-cover"
                  src="https://images.unsplash.com/photo-1560185893-a55cbc8c57e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  alt=""
                />
              </div>
              <div className="px-5 py-5">
                <h3 className="text-center text-xl font-bold text-secondary mb-2">
                  Residential
                </h3>
                <p className="text-secondary mb-3">
                  Home renovation can be a great way to improve the look and
                  feel of your home. We handle all type of projects to give an
                  entire room or home a new look.
                </p>
                <Link
                  to="/"
                  className="flex items-center font-bold justify-center transition-all ease-in-out duration-300 rounded-md text-primary"
                >
                  Learn More
                  <FaLongArrowAltRight className="ml-2 text-lg" />
                </Link>
              </div>
            </div>
          </div>
          <div onClick={clickCommercial}>
            <div className="rounded bg-white shadow-md hover:shadow-xl hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer">
              <div>
                <img
                  className="rounded-t"
                  src="https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                  alt=""
                />
              </div>
              <div className="px-5 py-5">
                <h3 className="text-center text-xl font-bold text-seconbdary mb-2">
                  Commercial
                </h3>
                <p className="text-secondary mb-3">
                  Experienced in commercial buildings such as offices, retail
                  stores, and restaurants. It can help improve the look and feel
                  of the building and make it functional
                </p>
                <Link
                  to="/"
                  className="flex items-center font-bold justify-center transition-all ease-in-out duration-300 rounded-md text-primary"
                >
                  Learn More
                  <FaLongArrowAltRight className="ml-2 text-lg" />
                </Link>
              </div>
            </div>
          </div>
          <div onClick={clickOutdoor}>
            <div className="rounded bg-white shadow-md hover:shadow-xl hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer">
              <div>
                <img
                  className="rounded-t object-cover h-full"
                  src="https://images.unsplash.com/photo-1582268611958-ebfd161ef9cf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  alt=""
                />
              </div>
              <div className="px-5 py-5">
                <h3 className="text-center text-xl font-bold text-secondary mb-2">
                  Outdoor Space
                </h3>
                <p className="text-secondary mb-3">
                  We are experts at exterior renovations and povide renovating
                  the exterior of a building such as the roof, siding, windows,
                  doors, garden, patio, and outdoor space.
                </p>
                <Link
                  to="/"
                  className="flex items-center font-bold justify-center transition-all ease-in-out duration-300 rounded-md text-primary"
                >
                  Learn More
                  <FaLongArrowAltRight className="ml-2 text-lg" />
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="w-1/4 rounded bg-white shadow-md hover:shadow-xl hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer">
            <div>
              <img
                className="rounded-t"
                src="https://images.unsplash.com/photo-1560185893-a55cbc8c57e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt=""
              />
            </div>
            <div className="px-5 ">
              <h3 className="text-center text-2xl font-bold text-darkText mb-5">
                Residential
              </h3>
              <p className="text-textGray mb-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores possimus ducimus quis reprehenderit quasi quas libero
                maxime illo ipsam ex.
              </p>
              <Link
                to="/"
                className="flex items-center font-bold text-lg justify-center transition-all ease-in-out duration-300 py-2 px-3 rounded-md text-primary"
              >
                Learn More
                <FaLongArrowAltRight className="ml-2 mt-1 text-lg" />
              </Link>
            </div>
          </div> */}
          {/* <div className="w-1/4 rounded bg-white shadow-md hover:shadow-xl hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer">
            <img
              className="rounded-t"
              src="https://images.unsplash.com/photo-1560185893-a55cbc8c57e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div className="px-5 pt-5">
              <h3 className="text-center text-2xl font-bold text-darkText mb-5">
                Commercial
              </h3>
              <p className="text-textGray mb-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores possimus ducimus quis reprehenderit quasi quas libero
                maxime illo ipsam ex.
              </p>
              <Link
                to="/"
                className="flex items-center font-bold text-lg justify-center transition-all ease-in-out duration-300 py-2 px-3 rounded-md text-primary"
              >
                Learn More
                <FaLongArrowAltRight className="ml-2 mt-1 text-lg" />
              </Link>
            </div>
          </div> */}
          {/* <div className="w-1/4 rounded bg-white shadow-md hover:shadow-xl hover:scale-105 transition-all duration-200 ease-in-out cursor-pointer">
            <img
              className="rounded-t"
              src="https://images.unsplash.com/photo-1560185893-a55cbc8c57e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              alt=""
            />
            <div className="px-5 pt-5">
              <h3 className="text-center text-2xl font-bold text-darkText mb-5">
                Outdoor Space
              </h3>
              <p className="text-textGray mb-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores possimus ducimus quis reprehenderit quasi quas libero
                maxime illo ipsam ex.
              </p>
              <Link
                to="/"
                className="flex items-center font-bold text-lg justify-center transition-all ease-in-out duration-300 py-2 px-3 rounded-md text-primary"
              >
                Learn More
                <FaLongArrowAltRight className="ml-2 mt-1 text-lg" />
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ServicePage;
