import React from "react";

function PagesTopBanner({ title }) {
  return (
    <div className="py-40 service-hero relative">
      <div className="absolute h-full w-full top-0 left-0 bg-black opacity-50"></div>
      <div className="container absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-20">
        <h1 className="text-white text-center mt-10 text-3xl lg:mt-0 lg:text-5xl">
          {title}
        </h1>
      </div>
    </div>
  );
}

export default PagesTopBanner;
