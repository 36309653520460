import React from "react";
import Contact from "../components/Contact";
import PagesTopBanner from "../components/PagesTopBanner";

function ContactPage() {
  return (
    <div>
      <PagesTopBanner title="Contact us" />
      <Contact />
    </div>
  );
}

export default ContactPage;
