import React from "react";
import NavBar from "./components/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import OutdoorPage from "./pages/OutdoorPage";
import ResidentialPage from "./pages/ResidentialPage";
import CommercialPage from "./pages/CommercialPage";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import ServicesLayout from "./pages/ServicesLayout";
import ServicePage from "./pages/ServicePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesLayout />}>
          <Route index element={<ServicePage />} />
          <Route path="residential" element={<ResidentialPage />} />
          <Route path="commercial" element={<CommercialPage />} />
          {/* <Route path="outdoor" element={<TestPage />} /> */}
          <Route path="outdoor" element={<OutdoorPage />} />
        </Route>
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
