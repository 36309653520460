import React from "react";
import PagesTopBanner from "../components/PagesTopBanner";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import signature from "../assets/signature.png";
import { MdAccountBalance } from "react-icons/md";
import { FaRegHandshake } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import Call from "../components/Call";
import Members from "../components/Members";
import Testimonial from "../components/Testimonial";

function AboutPage() {
  return (
    <>
      <PagesTopBanner title="About Us" />
      <div className="container my-10 mx-auto px-5 lg:px-20 flex-col md:flex-row flex">
        <div className="md:w-1/2 pr-5">
          <h2 className="text-2xl md:text-4xl text-secondary font-corm font-bold">
            E Daley Contracting
          </h2>
          <div className="w-20 h-[2px] bg-primary mt-5"></div>
          <p className="font-open text-[#757980] mt-10 text-base">
            Where untrammelled power of creativity meets unwavering dedication,
            crafting boundless future from raw material.
          </p>
          <h4 className="text-xl md:text-2xl mt-10 mb-10 text-secondary  font-bold uppercase">
            Hire the best contractors for your job
          </h4>
          <ul>
            <li>
              <div className="flex items-center space-x-5">
                <AiOutlineSafetyCertificate className="text-primary text-3xl" />
                <h4 className="text-xl md:text-2xl text-secondary font-corm font-bold uppercase">
                  Certified
                </h4>
              </div>
              <span className="font-open text-[#757980] mt-10 text-base md:ml-14">
                Protecting Your Home From Damaging Leaks.
              </span>
            </li>
            <li className="mt-5">
              <div className="flex items-center space-x-5">
                <IoSettingsOutline className="text-primary text-3xl" />
                <h4 className="text-xl md:text-2xl text-secondary font-corm font-bold uppercase">
                  Innovative Work
                </h4>
              </div>
              <span className="capitalize font-open text-[#757980] mt-10 text-base md:ml-14">
                Designing with the state of the aret technology.
              </span>
            </li>
            <li className="mt-5">
              <div className="flex items-center space-x-5">
                <MdOutlineMapsHomeWork className="text-primary text-3xl" />
                <h4 className="text-xl md:text-2xl text-secondary font-corm font-bold uppercase">
                  Experienced
                </h4>
              </div>
              <span className="capitalize font-open text-[#757980] mt-10 text-base md:ml-14">
                Contracting is our heritage. Quality is our tradition.
              </span>
            </li>
          </ul>
          <div className="mt-10 flex flex-col-reverse md:flex-row justify-between items-center ">
            <div className="mt-2 md:mt-0 w-3/5 md:-mr-24">
              <img src={signature} className="md:h-[90px]" alt="" />
            </div>
            <div className="hidden md:block w-[2px] h-14 bg-gray-500 opacity-20"></div>
            <div className="md:w-1/3 ml-5 flex flex-col h-full">
              <h4 className="text-xl md:text-2xl text-secondary font-corm font-bold uppercase">
                Ewan Daley
              </h4>
              <p className="text-base font-open text-primary mt-2 ">
                CEO & Founder
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/2 pl-5 relative ">
          <div className="hidden md:flex flex-col items-end mr-20 mt-10">
            <h1 className="width-[25px] text-8xl text-primary font-corm font-bold uppercase">
              20+
            </h1>
            <p className="text-2xl text-right mr-10 w-[100px] font-open font-bold">
              Years of experience
            </p>
            <img
              src="https://images.unsplash.com/photo-1621905252507-b35492cc74b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
              className="hidden md:block absolute w-[350px] h-[350px] object-cover top-0 left-0 pr-1 z-10"
              alt=""
            />
            <div className="hidden md:block w-[350px] h-[350px] border-2 border-primary top-3 left-3 absolute"></div>
            <img
              src="https://images.unsplash.com/photo-1626885930974-4b69aa21bbf9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1492&q=80"
              className="hidden md:block absolute bottom-0 right-0 w-[350px] h-[350px] z-10 object-cover"
              alt=""
            />
            <div className="hidden md:block w-[350px] h-[350px] border-2 border-primary -bottom-3 -right-3 absolute"></div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container border-t-[1px] border-[#757980] border-opacity-10 lg:px-20 py-10 mx-auto ">
          <h4 className="text-xl md:text-2xl text-secondary font-corm font-bold uppercase text-center ">
            Why Choose Us
          </h4>
          <div className="w-10 h-[2px] bg-primary mt-5 mx-auto"></div>
          <p className="font-open text-[#757980] mt-10 text-base px-5 md:px-20 text-center">
            Ewan Daley Jr. President, with over a decade of experience in the
            architecture and construction industry. I specialize in commercial
            and residential construction and remodeling. My specialties include
            Technical Drawings, Project Management and General Construction.
            Extensive knowledge of Ontario Building Codes and Safety
            Regulations. I'm dedicated to my profession and the mission of
            always exceeding my client’s expectations and delivering excellent
            customer service.
          </p>
          <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 pt-10">
            <div className="w-1/3 mx-auto md:mx-0  text-center">
              <MdAccountBalance className="mx-auto text-9xl text-primary p-6  rounded-full border border-primary " />
              <h4 className="text-xl mt-5 text-secondary font-corm font-bold uppercase text-center ">
                Professional
              </h4>
            </div>
            <div className="w-1/3 mx-auto md:mx-0  text-center">
              <FaRegHandshake className="mx-auto text-9xl text-primary p-6  rounded-full border border-primary " />
              <h4 className="text-xl mt-5 text-secondary font-corm font-bold uppercase text-center ">
                Trusted
              </h4>
            </div>
            <div className="w-1/3 mx-auto md:mx-0  text-center">
              <FaTools className="mx-auto text-9xl text-primary p-6  rounded-full border border-primary " />
              <h4 className="text-xl mt-5 text-secondary font-corm font-bold uppercase text-center ">
                Percision
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Members />
      <Testimonial />
    </>
  );
}

export default AboutPage;
