import React from "react";
import { NavLink } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoWalletOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import ServiceImage from "../components/ServiceImage";

function CommercialPage() {
  return (
    <div>
      <div className="container py-10 px-5 xl:px-20 mx-auto flex-col-reverse md:flex-row flex md:space-x-5">
        <div className=" w-full md:w-1/4 xl:w-1/5 mt-10 md:mt-0">
          <ul className="list-none flex flex-col space-y-2">
            <li>
              <NavLink
                to="/services/residential"
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between text-secondary bg-white shadow-md items-center hover:text-white hover:bg-primary"
                }
              >
                Residential <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services/commercial"
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between  text-secondary bg-white items-center hover:text-white hover:bg-primary"
                }
              >
                Commercial <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services/outdoor"
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between  text-secondary bg-white items-center shadow-md hover:text-white hover:bg-primary"
                }
              >
                Outdoor Space <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
          </ul>
          <div className="borde shadow-md bg-white border-[#E2E6E7] mt-10 h-[400px] py-10 px-5 rounded ">
            <span className="block">
              <IoWalletOutline className="text-primary border border-[#E2E6E7] rounded-full p-4 text-8xl text-center mx-auto" />
            </span>
            <h4 className="text-2xl text-secondary text-corm font-bold text-center mt-5">
              Don't Hesitate
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5 mx-auto"></div>
            <p className="font-open text-secondary mt-5 text-base">
              Trusted by your neighbors and communities near you
            </p>
            <div className="text-center mt-10">
              <Link
                to="/contact"
                className=" bg-primary hover:bg-primaryDark transition-all ease-in-out duration-300 py-2 px-3 rounded-md shadow-md text-white"
              >
                Request A Quote
              </Link>
            </div>
          </div>
        </div>

        {/* Second Column */}
        <div className=" w-full md:w-3/4 xl:w-4/5">
          <div className="w-full flex mb-10">
            <img
              className="block w-1/2 pr-1 md:pr-5"
              src="https://images.unsplash.com/photo-1497366216548-37526070297c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80"
              alt=""
            />
            <img
              className="w-block w-1/2 pl-1 md:pl-5"
              src="https://images.unsplash.com/photo-1531972111231-7482a960e109?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-2xl text-secondary text-corm font-bold">
              Commercial Services
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5"></div>
            <p className="font-open text-secondary mt-10 text-base">
              At E Daley Contracting, we are proud to have worked on a wide
              range of commercial construction projects. From office buildings
              and retail stores to warehouses and factories, we have experience
              in all types of commercial construction. We understand the
              importance of creating spaces that meet the needs of our clients,
              and we strive to use the best materials and techniques to ensure
              that our projects are of the highest quality. Our team is
              experienced and knowledgeable, and we always go the extra mile to
              make sure that our clients are satisfied with the end results.
              Contact us today to learn more about our commercial construction
              projects!
            </p>
            <p className="font-open text-secondary mt-10 text-base">
              E Daley Contracting has proven results for setting exceptional
              standards in cost control, planning, scheduling and project
              safety. We have experience that gives us a competitive advantage
              over others in our field.
            </p>
            <h4 className="text-2xl text-secondary text-corm font-bold mt-10">
              We Provide A Wide Array Of Commercial Services, Including:
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5"></div>
            <ul className="mt-10">
              <li className="text-textGray font-open">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Designing Blueprints and/or 3d Modelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Full Office Remodelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Full Bathroom Remodelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Painting and Sanding
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Flooring and Tiling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Duct Cleaning
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Full Electrical Services
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Full Plumbing Services
                </div>
              </li>
            </ul>
          </div>
          <ServiceImage />
        </div>
      </div>
    </div>
  );
}

export default CommercialPage;
