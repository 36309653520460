import React from "react";
import pattern from "../assets/pattern.png";
import { IoCreateSharp } from "react-icons/io5";
import { BsBricks } from "react-icons/bs";
import {
  FaCashRegister,
  FaRegHandshake,
  FaLongArrowAltRight,
} from "react-icons/fa";

function Call() {
  return (
    <section
      className=" bg-secondary py-10 md:py-10  w-full"
      style={{ backgroundImage: "url(" + pattern + ")" }}
    >
      {/* <img src={pattern} className="object-cover" alt="" /> */}
      <h1 className="text-center text-white text-xl lg:text-2xl font-bold tracking-wide mb-10">
        Simplified Process
      </h1>
      <div className="h-full container space-y-4 lg:space-y-0  mx-auto  px-5 md:px-20 flex flex-col lg:flex-row space-between items-center">
        {/* <h4 className="text-lg md:text-2xl font-corm tracking-wide text-white">
          Get Professional Services, Call us Now.{" "}
          <span className="font-bold">
            <a href="tel:416-509-0853">(416)-509-0853</a>
          </span>
        </h4> */}

        <div className="flex flex-col items-center">
          <IoCreateSharp className=" text-bodyColor text-6xl" />
          <h2 className="text-primary text-base lg:text-xl font-bold tracking-wide mt-2">
            Design Project
          </h2>
          <p className="text-white tracking-wide text-sm lg:text-base text-center px-2 mt-2">
            Design-project preparation in accordance with clients requirements
          </p>
        </div>
        <div>
          <FaLongArrowAltRight className="hidden lg:block text-primary text-2xl" />
        </div>
        <div className="flex flex-col items-center">
          <BsBricks className="text-white text-6xl " />
          <h2 className="text-primary text-base lg:text-xl font-bold tracking-wide mt-2">
            Construction & Renovation
          </h2>
          <p className="text-white text-center text-sm lg:text-base px-2 mt-2">
            The process of construction and renovation according to project
          </p>
        </div>
        <div>
          <FaLongArrowAltRight className="hidden lg:block text-primary text-2xl" />
        </div>
        <div className="flex flex-col items-center">
          <FaCashRegister className="text-white text-6xl" />
          <h2 className="text-primary text-base lg:text-xl font-bold tracking-wide mt-2">
            Budget Approval
          </h2>
          <p className="text-white text-sm lg:text-base text-center px-2 mt-2">
            Budget analysis, corrections and approval with the client
          </p>
        </div>
        <div>
          <FaLongArrowAltRight className="hidden lg:block text-primary text-2xl" />
        </div>
        <div className="flex flex-col items-center">
          <FaRegHandshake className="text-white  text-6xl" />
          <h2 className="text-primary text-base lg:text-xl font-bold tracking-wide mt-2">
            Handover
          </h2>
          <p className="text-white text-sm lg:text-base text-center px-2 mt-2">
            A full walk-through of the project with the client and the architect
          </p>
        </div>
      </div>
    </section>
  );
}

export default Call;
