import React from "react";
import Hero from "../components/Hero";
import Slider from "../components/Slider";
import Testimonial from "../components/Testimonial";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Call from "../components/Call";
import WeDo from "../components/WeDo";
import Kontakt from "../components/Kontakt";
import Divider from "../components/Divider";

function HomePage() {
  return (
    <div>
      <Hero />
      <Slider />
      <Divider />
      {/* <Kontakt /> */}
      <Call />
      <WeDo />
      <Contact />
      <Testimonial />
    </div>
  );
}

export default HomePage;
