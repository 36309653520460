import React from "react";
import { NavLink } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoWalletOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import ServiceImage from "../components/ServiceImage";

function OutdoorPage() {
  return (
    <div>
      <div className="container py-10 px-5 xl:px-20 mx-auto flex-col-reverse md:flex-row flex md:space-x-5">
        <div className=" w-full md:w-1/4 xl:w-1/5 mt-10 md:mt-0">
          <ul className="list-none flex flex-col space-y-2">
            <li>
              <NavLink
                to="/services/residential"
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between bg-white shadow-md items-center hover:text-white hover:bg-primary"
                }
              >
                Residential <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services/commercial"
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between bg-white shadow-md items-center hover:text-white hover:bg-primary"
                }
              >
                Commercial <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services/outdoor"
                className={({ isActive }) =>
                  isActive
                    ? "text-white font-open flex py-4 px-4 justify-between shadow-md bg-primary items-center"
                    : "font-open flex py-4 px-4 justify-between bg-white shadow-md items-center hover:text-white hover:bg-primary"
                }
              >
                Outdoor Space <FaLongArrowAltRight className="mt-1" />
              </NavLink>
            </li>
          </ul>
          <div className="border bg-white rounded shadow-md border-[#E2E6E7] mt-10 h-[400px] py-10 px-5 ">
            <span className="block">
              <IoWalletOutline className="text-primary border border-[#E2E6E7] rounded-full p-4 text-8xl text-center mx-auto" />
            </span>
            <h4 className="text-2xl text-darkText text-corm font-bold text-center mt-5">
              Don't Hesitate
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5 mx-auto"></div>
            <p className="font-open text-textGray mt-5 text-base">
              Trusted by your neighbors and communities near you
            </p>
            <div className="text-center mt-10">
              <Link
                to="/contact"
                className=" bg-primary hover:bg-primaryDark transition-all ease-in-out duration-300 py-2 px-3 rounded-md shadow-md text-white"
              >
                Request A Quote
              </Link>
            </div>
          </div>
        </div>

        {/* Second Column */}
        <div className=" w-full md:w-3/4 xl:w-4/5">
          <div className="w-full flex mb-10">
            <img
              className="block w-1/2 pr-1 md:pr-5"
              src="https://images.unsplash.com/photo-1602774895672-b553538bceb9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              alt=""
            />
            <img
              className="w-block w-1/2 pl-1 md:pl-5"
              src="https://plus.unsplash.com/premium_photo-1682377521705-bfaed7549ab4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-2xl text-darkText text-corm font-bold">
              Outdoor Services
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5"></div>
            <p className="font-open text-textGray mt-10 text-base">
              At E Daley Contracting, we are dedicated to creating beautiful,
              functional outdoor spaces that are tailored to meet your needs and
              budget. Our experienced team of professionals can handle any
              project, from simple landscape updates to major hardscape
              renovations. We specialize in creating custom outdoor living
              spaces and offer a variety of services, including landscape
              design, hardscaping, fence and deck installation, and custom
              outdoor structures. Our commitment to quality and customer
              satisfaction ensures that we deliver the highest level of service
              every time. We understand that your outdoor space is an extension
              of your home, and we take pride in delivering a beautiful,
              functional outdoor area that you can enjoy for years to come.
            </p>
            <p className="font-open text-textGray mt-10 text-base">
              We specialize in exceptional exterior renovations, transforming
              your property into a stunning oasis. From enchanting driveways to
              inviting porches, captivating pools to elegant gazebos, and even
              your roof, windows and doors - our expertise covers it all. Our
              goal is to create space where you can truly savor every moment and
              relish in the joy of your surroundings.
            </p>
            <h4 className="text-2xl text-darkText text-corm font-bold mt-10">
              We Provide A Wide Array Of Outdoor Services, Including:
            </h4>
            <div className="w-10 h-[2px] bg-primary mt-5"></div>
            <ul className="mt-10">
              <li className="text-textGray font-open">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Designing Blueprints and/or 3d Modelling
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Patio & Decks
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Swimming Pool
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5">
                    <FaCheck />
                  </span>
                  Landscaping
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Windows & Doors
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Roofing
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Driveways & Pathways
                </div>
              </li>
              <li className="text-textGray font-open mt-2">
                <div className="flex items-center">
                  <span className="text-primary mr-5 ">
                    <FaCheck />
                  </span>
                  Lighting
                </div>
              </li>
            </ul>
          </div>
          <ServiceImage />
        </div>
      </div>
    </div>
  );
}

export default OutdoorPage;
