import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import emailjs from "@emailjs/browser";
import "leaflet/dist/leaflet.css";
import tools from "../assets/tools.jpg";
import { toast, ToastContainer } from "react-toastify";

const initialState = {
  firstName: "",
  lastName: "",
  userEmail: "",
  description: "",
};

function Contact() {
  const [formValues, setFormValues] = useState(initialState);
  const form = useRef();

  const { firstName, lastName, userEmail, description } = formValues;
  const formHandler = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const result = await emailjs.sendForm(
        "service_udc1sfc",
        "template_2sjxuci",
        form.current,
        "NfDwP4xjBMtekZT5U"
      );

      // console.log(result);

      toast(
        "Your message has been sent. An associate will review and respond shortly."
      );

      setFormValues({
        firstName: "",
        lastName: "",
        userEmail: "",
        description: "",
      });
    } catch (error) {
      console.log(error.text);
    }
  };

  return (
    <div
      className="w-full relative object-cover bg-bodyColor"
      style={{ backgroundImage: `url(${tools})` }}
    >
      <div className="absolute -z-5 top-0 left-0 w-full h-full bg-black opacity-60"></div>
      <div className="container mx-auto px-5 py-10 md:p-10 flex-col lg:flex-row flex ">
        <div className="w-full z-10 bg-white shadow-lg rounded-lg px-5 lg:px-10 py-10">
          <div className="flex items-center justify-center space-x-2">
            <div className="flex flex-col space-y-1">
              <div className="h-[2px] w-[15px] bg-primary" />
              <div className="h-[2px] w-[20px] bg-primary" />
            </div>
            <h2 className="text-primary text-lg font-mainFont font-semibold">
              Contact us
            </h2>
          </div>
          <h1 className="text-secondary text-center lg:text-left text-3xl font-mainFont my-3 font-semibold tracking-wide">
            Have Questions? Get In Touch!
          </h1>
          <form
            ref={form}
            className="w-full mt-5 lg:pr-3"
            onSubmit={submitHandler}
          >
            <div className="flex flex-wrap">
              <div className="w-1/2 pr-3 mb-6">
                <input
                  className="appearance-none block w-full bg-bgGray text-gray-700 border-b-[1px]  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="First name"
                  name="firstName"
                  value={firstName}
                  onChange={formHandler}
                />
              </div>
              <div className="w-1/2 pl-3 mb-6">
                <input
                  className="appearance-none block w-full bg-bgGray text-gray-700 border-b-[1px] border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  value={lastName}
                  onChange={formHandler}
                />
              </div>
            </div>
            <div className="w-full mb-6">
              <input
                className="appearance-none mb-3 block  w-full bg-bgGray text-secondary border-b-[1px] border-gray-200  py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="email"
                placeholder="Email address"
                name="userEmail"
                value={userEmail}
                onChange={formHandler}
              />
            </div>
            <div className="w-full mb-10">
              <textarea
                className="appearance-none block w-full bg-bgGray text-gray-700 border-b-[1px] border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="description"
                placeholder="Description"
                rows="1"
                col="50"
                name="description"
                value={description}
                onChange={formHandler}
              />
            </div>
            <div className="text-center my-5">
              <button className="w-full bg-primary hover:bg-primaryDark transition-all ease-in-out duration-300 py-2 px-3 rounded-md shadow-lg text-white text-base lg:text-xl">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="h-100 w-full z-10  lg:pl-3">
          <MapContainer
            style={{ width: "100%", height: "100%" }}
            center={[43.6532, -79.3832]}
            zoom={8}
            scrollWheelZoom={false}
            className="z-10"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </div>
      </div>
    </div>
  );
}

export default Contact;
