import React, { useState } from "react";
import logo from "../assets/logoHorizontal.svg";
import { NavLink, Link, useLocation } from "react-router-dom";
import { FaBars, FaChevronDown } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const mobileMenuVariant = {
  opened: {
    y: "0%",
    transition: {
      delay: 0.15,
      duration: 1.1,
      ease: [0.74, 0, 0.19, 1.02],
    },
  },
  closed: {
    y: "-120%",
    transition: {
      delay: 0.35,
      duration: 0.63,
      ease: [0.74, 0, 0.19, 1.02],
    },
  },
};

const ulVariant = {
  opened: {
    transition: {
      delayChildren: 1,
      staggerChildren: 0.18,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.06,
      staggerDirection: -1,
    },
  },
};

const liVariant = {
  opened: {
    opacity: 1,
    y: "0%",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
  closed: {
    opacity: 0,
    y: "100%",
    transition: {
      duration: 0.25,
      ease: "easeInOut",
    },
  },
};

function NavBar() {
  const location = useLocation();
  const [isMobileNav, setIsMobileNav] = useState(false);
  const [expandService, setExpandService] = useState(false);

  const mobileNavHandler = () => {
    setIsMobileNav(!isMobileNav);
    setExpandService(false);
  };

  const showServicesHandler = () => {
    setExpandService(!expandService);
  };

  const closeServices = () => {
    setExpandService(false);
  };

  return (
    <div className="border-[1px] w-full border-[#D7D8D9] z-50 bg-white fixed top-0 left-0">
      <div className=" bg-white px-5 md:px-10 lg:px-20 z-50 py-4 mx-auto w-full flex justify-between items-center">
        <div className="">
          <Link to="/">
            <img src={logo} className="w-[160px]" alt="logo" />
          </Link>
        </div>
        <ul className="space-x-14 hidden lg:flex">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "font-mainFont text-secondary font-bold"
                  : "font-mainFont text-secondary"
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                isActive
                  ? "font-mainFont text-secondary font-bold"
                  : "font-mainFont text-secondary"
              }
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "font-mainFont text-secondary font-bold"
                  : "font-mainFont text-secondary"
              }
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "font-mainFont text-secondary font-bold"
                  : "font-mainFont text-secondary"
              }
            >
              Contact
            </NavLink>
          </li>
          <li>
            <Link
              to="/contact"
              className="font-mainFont text-white bg-primary py-2 px-3 rounded-md shadow-md hover:bg-primaryDark transition-all ease-in-out duration-300"
            >
              Free Consultation
            </Link>
          </li>
        </ul>
        <FaBars
          onClick={mobileNavHandler}
          className="lg:hidden text-3xl text-secondary cursor-pointer"
        />
      </div>
      {/* MOBILE MENU */}
      <motion.div
        key="mobile"
        variants={mobileMenuVariant}
        initial="closed"
        animate={isMobileNav ? "opened" : "closed"}
        className="lg:hidden fixed left-0 top-[85.33px] -z-10 h-[calc(100vh-85.33px)] w-screen bg-secondary pt-10"
      >
        <motion.ul
          variants={ulVariant}
          className="flex flex-col items-center space-y-10 font-mainFont"
        >
          <motion.li variants={liVariant}>
            <Link onClick={mobileNavHandler} to="/" className="text-white">
              Home
            </Link>
          </motion.li>
          <motion.li variants={liVariant}>
            <Link
              onClick={expandService ? mobileNavHandler : showServicesHandler}
              to={expandService && "/services"}
              className="text-white flex items-center"
            >
              Services
              <span>
                <FaChevronDown className="ml-4 text-sm" />
              </span>
            </Link>
            {expandService && (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="h-[1px] mt-2 bg-[#D7D8D9] opacity-40"
                ></motion.div>
                <motion.ul
                  key="motionList"
                  variants={ulVariant}
                  className="text-white flex flex-col items-center mt-2 space-y-2"
                >
                  <motion.li variants={liVariant} className="py-1">
                    <Link
                      to="/services/residential"
                      className="hover:scale-150"
                      onClick={mobileNavHandler}
                    >
                      Residential
                    </Link>
                  </motion.li>
                  <motion.li variants={liVariant} className="py-1">
                    <Link to="/services/commercial" onClick={mobileNavHandler}>
                      Commercial
                    </Link>
                  </motion.li>
                  <motion.li variants={liVariant} className="py-1">
                    <Link to="/services/outdoor" onClick={mobileNavHandler}>
                      Outdoor
                    </Link>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </motion.li>
          <motion.li variants={liVariant}>
            <Link to="/about" onClick={mobileNavHandler} className="text-white">
              About
            </Link>
          </motion.li>
          <motion.li variants={liVariant}>
            <Link
              to="/contact"
              onClick={mobileNavHandler}
              className="text-white"
            >
              Contact
            </Link>
          </motion.li>
          <motion.li variants={liVariant}>
            <Link
              to="/contact"
              className="font-mainFont text-white bg-primary py-2 px-3 rounded-md shadow-md hover:bg-primaryDark transition-all ease-in-out duration-300 "
              onClick={mobileNavHandler}
            >
              Free Consultation
            </Link>
          </motion.li>
        </motion.ul>
      </motion.div>
    </div>
  );
}

export default NavBar;
