import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MdOutlineMail } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import PagesTopBanner from "../components/PagesTopBanner";

function ServicesLayout() {
  const location = useLocation();

  return (
    <>
      {" "}
      <PagesTopBanner
        title={
          location.pathname === "/services/residential"
            ? "Residential Services"
            : location.pathname === "/services/commercial"
            ? "Commercial Services"
            : location.pathname === "/services/outdoor"
            ? "Outdoor Services"
            : "Services"
        }
      />
      <Outlet />
      <div className=" services-banner h-[400px] md:h-[500px] relative">
        <div className="absolute top-0 left-0 bg-black h-full w-full opacity-75"></div>
        <div className="container h-[400px] absolute  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-5 py-10 md:border-2 border-primary flex flex-col space-y-2 items-center justify-center">
          <h1 className="text-white text-xl text-center md:text-left md:text-2xl font-bold font-corm">
            Creating Value at E Daley Contracting
          </h1>
          <p className="text-lg text-center md:text-left md:text-3xl text-primary font-corm">
            Talk to Us! Call Now : 437.995.1757
          </p>
          <div className="flex flex-col space-y-2 sm:space-x-0 md:space-y-0 md:flex-row md:space-x-5 items-center">
            <div className="flex items-center">
              <MdOutlineMail className="text-primary text-lg md:text-xl mr-2" />
              <p className="text-white text-lg md:text-xl">
                edaley.gta.@gmail.com
              </p>
            </div>
            <div className="hidden md:block text-white items-center"></div>
            <div className="flex  items-center">
              <FaRegClock className="text-primary text-lg md:text-xl mr-2" />
              <p className="text-white text-lg md:text-xl">Mon-Sun-24/7</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesLayout;
