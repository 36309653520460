import React from "react";
import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function Testimonial() {
  return (
    <div className="md:h-auto bg-bodyColor font-mainFont">
      <div className="container px-5 md:px-20 mx-auto py-10">
        <h2 className="text-center text-4xl font-corm font-bold text-secondary">
          Testimonials
        </h2>
        <div className="md:hidden">
          <Swiper
            pagination={{ clickable: true }}
            spaceBetween={20}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <div className="bg-white w-full rounded-md shadow-md  px-10">
                <div className="flex justify-center pt-10 mt-10 text-primary space-x-1">
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                </div>
                <p className="mt-10 text-[#757980] italic font-bold font-mainFont">
                  Just to let you know your team is the best I’ve ever had the
                  pleasure of working with! They were professional, polite, and
                  the work was exceptional. The speed they did this job was
                  remarkable
                </p>
                <h1 className="font-mainFont text-xl text-darkText font-bold mt-10 text-center">
                  Thomas Williams
                </h1>
                <h1 className="font-mainFont text-primary font-bold pb-10 mb-3 text-center">
                  Brampton, Ontario
                </h1>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white w-full rounded-md shadow-lg  px-10">
                <div className="flex justify-center pt-10 mt-10 text-primary space-x-1">
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                </div>
                <p className="mt-10 text-[#757980] italic font-bold font-mainFont">
                  Our bathroom was old fashioned, dark and without any operable
                  windows. After the renovation it is a showplace! They worked
                  closely with us to give us exactly what we dreamed of and lots
                  more!!!
                </p>
                <h1 className="font-corm text-xl text-darkText font-bold mt-10 text-center">
                  Peter Yan
                </h1>
                <h1 className="font-corm text-primary font-bold pb-10 mb-3 text-center">
                  Scarborough, Ontario
                </h1>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-white w-full rounded-md drop-shadow-md mb-10 px-10">
                <div className="flex justify-center pt-10 mt-10 text-primary space-x-1">
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                  <span>
                    <FaStar />
                  </span>
                </div>
                <p className="mt-10 text-[#757980] italic font-bold font-mainFont">
                  We like this company so well that we are going to hire them to
                  help us get our condo ready to sell. We are bringing them
                  aboard next year to build a master bedroom. We will be repeat
                  customers…
                </p>
                <h1 className="font-corm text-xl text-darkText font-bold mt-10 text-center">
                  Brian Banks
                </h1>
                <h1 className="font-corm text-primary font-bold pb-10 mb-3 text-center">
                  Vaughan, Ontario
                </h1>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="hidden md:flex space-y-5 lg:space-y-0 flex-col lg:flex-row h-1/2  lg:space-x-10 mt-10">
          <div className="bg-white w-full lg:w-1/3 rounded-md shadow-lg px-10">
            <div className="flex justify-center mt-10 text-primary space-x-1">
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
            </div>
            <p className="mt-10 text-[#757980] font-bold italic font-open">
              Just to let you know your team is the best I’ve ever had the
              pleasure of working with! They were professional, polite, and the
              work was exceptional. The speed they did this job was remarkable
            </p>
            <h1 className="font-corm text-xl text-secondary  font-bold mt-10 text-center">
              Thomas Williams
            </h1>
            <h1 className="font-corm text-primary font-bold mb-3 text-center">
              Brampton, Ontario
            </h1>
          </div>
          <div className="bg-white w-full lg:w-1/3 rounded-md shadow-lg px-10">
            {" "}
            <div className="flex justify-center mt-10 text-primary space-x-1">
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
            </div>
            <p className="mt-10 text-[#757980] font-bold italic font-open">
              Our bathroom was old fashioned, dark and without any operable
              windows. After the renovation it is a showplace! They worked
              closely with us to give us exactly what we dreamed of and lots
              more!!!
            </p>
            <h1 className="font-corm text-xl text-darkText font-bold mt-10 text-center">
              Peter Yan
            </h1>
            <h1 className="font-corm text-primary font-bold mb-3 text-center">
              Scarborough, Ontario
            </h1>
          </div>
          <div className="bg-white w-full lg:w-1/3 rounded-md shadow-lg px-10">
            {" "}
            <div className="flex justify-center mt-10 text-primary space-x-1">
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
              <span>
                <FaStar />
              </span>
            </div>
            <p className="mt-10 text-[#757980] font-bold italic font-open">
              We like this company so well that we are going to hire them to
              help us get our condo ready to sell. We are bringing them aboard
              next year to build a master bedroom. We will be repeat customers…
            </p>
            <h1 className="font-corm text-xl text-darkText font-bold mt-10 text-center">
              Brian Banks
            </h1>
            <h1 className="font-corm text-primary font-bold mb-3 text-center">
              Vaughan, Ontario
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
